html {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "FordAntennaLight"; /*Can be any text*/
  src: local("FordAntennaLight"),
    url("./fonts/FordAntennaLight.ttf") format("truetype");
}

@font-face {
  font-family: "FordAntennaBold"; /*Can be any text*/
  src: local("FordAntennaBold"),
    url("./fonts/FordAntennaBold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "FordAntennaLight";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
